import React from 'react'
import Header from './Header'

export default function NotFound() {
    return (
        <div>
            <Header />
            <div className="page-container">
                <div className="page-title">PAGE NOT FOUND!</div>
            </div>
        </div>
    )
}
