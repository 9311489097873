import React from 'react'
import ProjectHeader from '../ProjectHeader'
import { CodeBlock, dracula } from 'react-code-blocks'

export default function MorseCode() {

    const indexCode = `char letter;
int index = letter - 'a'; `

    const numberOfLinesCode = `std::ifstream file("words.txt");
int numberOfLines = 0;
std::string line;
while (std::getline(file, line)){
    ++numberOfLines;
}`

    return (
        <div className="page-container">
            <ProjectHeader title="Morse Codes" subtitle="Personal Project - December 2020" />
            <div className="page-section">
                At the start of our Christmas break, my flatmate and I made a pledge to learn Morse code so that
                we at least had something to do over the break. I started looking online for resources to learn from and
                found <a className="link" href="https://morse.withgoogle.com/learn/" target="_blank" rel="noopener noreferrer">Hello Morse</a>.
                Learning Morse code using this was super fun, but I needed more practice so I went online looking for a random Morse
                code word generator to test myself, but I was unable to find one that was sufficient for me. This is where the idea
                for this project came from. The GitHub repo for this project can be 
                found <a className="link" href="https://github.com/hassaanulhuda/morse-codes" target="_blank" rel="noopener noreferrer">here</a>.
                <br/>
                <br/>
                The base program is very simple. It runs entirely in a terminal (for the time being) and has two options: to either convert
                a word (or sentence) from to Morse or randomly pick a user-defined amount of words in Morse from a text list of English disctionary words. There is a common function 
                called <code>MorseConvert</code> which takes in a word or sentence in English and returns the Morse code conversion of it.
                In the generator mode, the program asks the user for the number of words that they wish to convert/generate in Morse. Once a number
                has been selected, the system prints out each word in a numbered list. The point of this is that the user can then write
                their answers down somewhere, with a reference for each word (the number). After continuing, the user is presented with the 
                corresponding answers to check against and the option to return to the main menu or quit the program. (shown below)
                <div className="section-padding">
                    <img className="full-img" src="/Morse/generator-example.png" alt="Generator example" />
                </div>
                Currently, the words are selected from a local <code>.txt</code> file which contains a whole bunch of dictionary words written
                out separately on new lines. This helps the program parse through the <code>.txt</code> file easily. The issue with local dictionary
                files such as this is twofold: they aren't the most up-to-date version and they are only limited to one langauge (English). To improve this
                I would need to find either an API which I can use to get the latest version of the dictionary for a chosen langauge or scrape a website which
                contains the words in a easily parsable fasion.
            </div>
            <div className="page-section">
                <div className="text-subtitle">Converting to Morse</div>
                Before I was able to generate random Morse coded words, I needed to find a method to convert English words to Morse. The simplest solution was
                to use the integer representation of <code>char</code>s and use an array which contained the Morse coded alphabet. 
                But first, there were a few hurdles that had to be cleared to use this method. To start with, I needed to convert the <code>char</code>s to their
                ASCII integer counterparts. There is a clever trick that can be used here (line 2 below). There are two things that happen here. Firstly, since a
                mathematical operation is performed, the <code>char</code>s in the equation (the letter and 'a') convert to <code>int</code>s automatically.
                Secondly, taking away the 'a' from the letter outputs the index of the letter in the alphabet. For example, 'a' = 97 and 'b' = 98 so taking 'a' away
                from 'b' gives 1, which is the index of the letter 'b' in the alphabet (index of 'a' is 0).
                <div className="code-block">
                    <CodeBlock
                        text={indexCode}
                        language={"cpp"}
                        showLineNumbers={true}
                        theme={dracula}
                    />
                </div>
                The next step was to iterate through the word, and convert each letter to Morse code. After a quick scroll in the file with the 
                dictionary words, I discovered that there are uppercase letters for some words, such as pronouns. However, to use the method stated above, 
                the letters of the word need to be lowercase. This prompted a simple conversion to take place for each letter in the string using 
                the <code>std::tolower</code> method. All that was left to do was to make sure to catch any errors that may take place. I noticed that
                there were hyphens in the dictionary words list, so I made sure to add the Morse code for that into the array which contained the Morse
                coded alphabet in order. Lastly, if the letter/character was not in the range of acceptable indeces, a string with a space was returned.
            </div>
            <div className="page-section">
                <div className="text-subtitle">Generating Random Morse Words</div>
                With the conversion aspect figured out, the next challenge was to generate a set of random Morse coded words. The process would be as
                follows: generate a random line number for the <code>.txt</code> file, pick out the word at that line number, convert the word to Morse,
                save the word for the answers page and repeat for as many words that need to be generated. Easier said than done.
                Before I could just generate a random number, I needed a to define bounds in which the random number would lie in. The lower bound
                is obviously 0 and the upper bound in our case would be the number of lines in the <code>.txt</code> file, or in other words, the
                total number of words. The code below performs this:
                <div className="code-block">
                <CodeBlock
                        text={numberOfLinesCode}
                        language={"cpp"}
                        showLineNumbers={true}
                        theme={dracula}
                    />
                </div>
                With the bounds defined, it was time to produce a random number. C++ offers a <code>rand()</code> method in combination
                with <code>srand()</code> seeding method. However, there is one major issue with this method; the maximum random number (called <code>RAND_MAX</code>) it can
                produce is pre-defined and is determined based on a variety of different factors, though it is guaranteed to be at least
                32,767. This number is way smaller than the total number of words (~450k) and changing <code>RAND_MAX</code> is not recommended either. I began
                to look for different methods to produce random numbers in C++ and it became evident very quickly that I should use the C++11 <code>{"<random>"}</code> library.
                It contains random number engines which generate pseudo-random numbers using seed data as entropy 
                source <a className="link" href="https://en.cppreference.com/w/cpp/numeric/random#:~:text=random%20number%20engines%20generate%20pseudo-random%20numbers%20using%20seed%20data%20as%20entropy%20source." target="_blank" rel="noopener noreferrer">[1]</a>.
                Since I am not too concerned with how random the numbers are, I used the <code>minstd_rand</code> engine which is the newest "Minimum Standard" engine that
                is based on the <a className="link" href="https://en.wikipedia.org/wiki/Linear_congruential_generator" target="_blank" rel="noopener noreferrer">Linear Congruential Generator (LCG)</a>.
                This engine is then passed onto a <code>uniform_int_distribution</code> class which produces integer values evenly distributed a range, in our case [0, numberOfLines].
            </div> 
            
        </div>
    )
}