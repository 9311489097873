import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    root: {
        maxWidth: 250,
        borderRadius: 0,
    },
    link: {
        textDecoration: "none",
        color: "black"
    },
    media: {
        width: 250,
        height: 180,
    },
    text: {
        fontSize: "15px",
        fontFamily: "Mulish"
    },
    subtext: {
        paddingTop: "2px",
        fontSize: "12px",
        fontFamily: "Mulish"
    }
});

export default function CardGrid(props) {
    const classes = useStyles();

    const handleClick = (link) => {
        localStorage.pos = window.pageYOffset;
        if (link.startsWith("http")) window.open(link, '_blank');
        else window.location.href = "/projects" + link;
    }

    return (
        <div className="card">
            <Card className={classes.root}>
                <CardActionArea onClick={() => { handleClick(props.project.link) }}>
                    <CardMedia
                        className={classes.media}
                        image={props.project.image}
                        title={props.project.imageTitle}
                    />
                    <CardContent>
                        <Typography align="center" className={classes.text}>
                            {props.project.text}
                        </Typography>
                        <Typography align="center" className={classes.subtext}>
                            {props.project.subtext}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </div>
    );
}