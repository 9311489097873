import React from 'react'
import ProjectHeader from '../ProjectHeader'

export default function TVControl() {
    return (
        <div className="page-container">
            <ProjectHeader title="IoT TV Control" subtitle="Personal Project - September 2020" />
            <div className="page-section">
                <div>
                    <img className="full-img" src="/TVControl/esp_ir.jpeg" alt="ESP32 and IR" />
                </div>
                This was a very basic project I decided to do for myself after learning
                about MQTT and how to integrate almost anything into an IoT world. The
                problem? We have a Chromecast at home and when the TV turns off with the
                input still on the Chromecast, the Chromecast mysteriously stops
                working the next time it is turned on. To get around this, I have to change
                the input on the TV to one that's not being used and <i>then</i> turn the TV off.
                So, I decided to make Google Assistant commands to do these steps
                automatically when I told the TV to turn off...
                <br />
                <div className="image-container" >
                    <img className="ifttt-sc" src="/TVControl/ifttt_ss.png" alt="ifttt_screenshot" />
                    <div className="caption" > IFTTT card</div>
                </div>
                By connecting up a simple <b>IR Receiver</b> to an <b>Arduino Uno</b>, I was able to decode
                the IR signals from the remote for the corresponding buttons. After desoldering
                some IR LEDs from an old RC helicopter remote, I could send these signals to my TV
                with a half-second delay in between each emulated press of a button. The next step
                was to make this easy to do. I planned to use an <b>ESP32 as an MQTT client</b>, which would
                be <b>subscribed</b> to the <b>MQTT broker</b>, to which I would publish an ON or OFF using a client
                on my phone or something similar. To take this a step further, I wanted to be able to
                add the ability to use Google Assistant to turn on/off the TV so I planned to see which
                MQTT broker was a) free to use and b) could be integrated with Google Assistant using <b>IFTTT</b>
                . I came across <b>Adafruit IO</b> and decided to use that.
                <br />
                <div className="image-container">
                    <img className="adafruit-sc" src="/TVControl/adafruit_ss_s.png" alt="adafruit_screenshot" />
                    <div className="caption" >Adafruit Switch</div>
                </div>
                The setup from here on was relatively simple. I set up a feed on Adafruit IO
                and added a switch to it. Following some Adafruit IO tutorials, I connected the ESP32
                to the broker meaning I could now <b>publish</b> either an OFF (0) or an ON (1) to the ESP32.
                The ESP32, subscribed to the broker, would read this message and either send a simple
                ON signal to the TV or perform the 4 button presses required to turn off the TV. Soon
                after, the IFTTT connectivity was also complete (unsurprisingly the IFTTT logic is easy
                to follow) and I could turn my TV off by saying "Hey Google, turn off my TV". Huzzah!
            </div>
        </div>
    )
}
