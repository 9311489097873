import React from 'react'
import { NavLink } from 'react-router-dom'

export default function Header() {
    return (
            <div className="nav-bar">
                <div className="nav-bar-name">
                    <a className="name" href="/">Hassaan-Ul Huda</a>
                </div>
                <div className="nav-bar-links">
                    <NavLink to="/projects" className="projects" activeClassName="active">Projects</NavLink>
                    <NavLink to="/" className="home" activeClassName="active" exact>Home</NavLink>
                </div>
            </div>

    )
}
