import React from 'react';
import CardGrid from '../CardGrid'

export default function Projects() {

    React.useEffect(() => {
        if (localStorage.pos) {
            let pos = parseFloat(localStorage.pos);
            window.scroll({
                top: pos,
                behavior: 'smooth'
            });
            localStorage.clear();
        }
    }, []);


    const projects = [
        // {
        //     id: 12,
        //     image: "/cards/charco-2.png",
        //     imageTitle: "Charco Neurotech",
        //     text: "Charco Neurotech / CUE2",
        //     link: "/charco",
        //     subtext: "Prototyping a non-invasive vibrotactile stimulation medical device"
        // },
        // {
        //     id: 11,
        //     image: "/cards/charco-2.png",
        //     imageTitle: "Charco Neurotech",
        //     text: "Synthesizer",
        //     link: "/synth",
        //     subtext: "Real-time programming of a one-octave synthesizer"
        // },
        // {
        //     id: 10,
        //     image: "/cards/charco-2.png",
        //     imageTitle: "Charco Neurotech",
        //     text: "NIOS-II on FPGA",
        //     link: "/nios-ii",
        //     subtext: "Digital system design of a soft-processor on an FPGA"
        // },
        {
            id: 9,
            image: "/cards/parakeet.png",
            imageTitle: "Parakeet Logo",
            text: "Parakeet",
            link: "https://jaocbj.wixsite.com/esicheck",
            subtext: "Smart parking availability system using Raspberry Pi and React JS"
        },
        {
            id: 8,
            image: "/cards/morse-code.jpeg",
            imageTitle: "Morse Codes",
            text: "Morse Codes",
            link: "/morse-code",
            subtext: "Generating English dictionary words in Morse or converting to Morse"
        },
        {
            id: 7,
            image: "/cards/esp_ir.jpeg",
            imageTitle: "IoT TV Control",
            text: "IoT TV Control",
            link: "/tv-control",
            subtext: "Controlling a TV using an ESP32, a IR LED and Adafruit IO"
        },
        {
            id: 6,
            image: "/cards/ideas-lab.jpeg",
            imageTitle: "Ideas Lab",
            text: "Ideas Lab System",
            link: "/ideas-lab",
            subtext: "Full stack development of a web app for an IoT makerspace"
        },
        {
            id: 5,
            image: "/cards/app-screenshot.jpeg",
            imageTitle: "App",
            text: "Custom Android App",
            link: "/android",
            subtext: "Exploring Android Studio and making my first app"
        },
        {
            id: 4,
            image: "/cards/monica.jpg",
            imageTitle: "Monica",
            text: "Monica",
            link: "/monica",
            subtext: "Improving computer accessibility for people with mobility impairments"
        },
        // {
        //     id: 3,
        //     image: "/cards/mastermind.jpeg",
        //     imageTitle: "Mastermind",
        //     text: "Mastermind Code Breaker",
        //     link: "/mastermind",
        //     subtext: "Optimising algorithms to crack Mastermind codes using C++"
        // },
        {
            id: 2,
            image: "/cards/0190617_eee_undergraduate_lab_026.jpg",
            imageTitle: "EERover",
            text: "EERover",
            link: "/eerover",
            subtext: "Creating a remote-controlled rover to identify signals using Arduino"
        },
        {
            id: 1,
            image: "/cards/bookbetter.png",
            imageTitle: "BookBetter",
            text: "BookBetter",
            link: "/bookbetter",
            subtext: "Hotel booking application made with Tkinter and Kivy in Python"
        },
    ]

    return (
        <div className="page-container cards-setting">
            <div className="cards-grid">
                {projects.map((project) => <CardGrid key={project.id} project={project} />)}
            </div>
        </div>
    );
}