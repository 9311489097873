import React from 'react'


export default function Home() {
    return (
        // <div id="home">
        <div className="bio">
            <div className="bio_container">
                <img className="picture" src="portrait_cropped.jpg" alt="ME" />
                <div className="text-container">
                    <div className="title">Electronics Engineer</div>
                    <div className="subtitle">MEng Electrical and Electronic Engineering <br></br> Imperial College London</div>
                    <div className="text">Interested in Electronics, Med-Tech and Full Stack Engineering</div>
                    <div className="text">Programming: C/C++, JavaScript, Python, MATLAB, Verilog</div>
                    <div className="text">Website made with <a className="link" href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">React JS</a></div>
                    <div className="text bottom">
                        <a className="link" href="/projects">Projects</a>&nbsp;&nbsp;
                        <a className="link" href="https://www.linkedin.com/in/hassaanulhuda/">LinkedIn</a>&nbsp;&nbsp;
                        <a className="link" href="mailto:hassaanhuda@gmail.com">Email</a>
                    </div>
                </div>
            </div>
        </div>
        // </div>
    )
}
