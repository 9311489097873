import React from 'react'
import ProjectHeader from '../ProjectHeader'

export default function Mastermind() {
    return (
        <div className="page-container">
            <ProjectHeader title="Mastermind Code Breaker" subtitle="1st Year Solo Coding Project - February to March 2019" />
            <div className="page-section">
                This coding project was based entirely around the board game called Mastermind. Personally,
                I had never heard of this game before the project but I believe it's a fairly popular game. There
                are two roles in the game; a codemaker and a codebreaker. The codemaker chooses a code of
                length four from a choice of six different colours, with the option of multiple colours in
                the same code, without showing the codebreaker. The codebreaker now has around ten attempts to crack
                the code (same colours and order). Upon each attempt, the codemaker provides feedback to the
                codebreaker in the form of black and white pegs. A black peg indicates that the provided code
                has a correct colour in the correct place whereas a white peg indicates only that a correct
                colour has been chosen, but in the wrong place. In a typical game, the codebreaker has 10 guesses
                in total to crack the code. Try it out for yourself - here is a link to play the 
                game: <a className="link" href="https://www.webgamesonline.com/mastermind/" target="_blank" rel="noopener noreferrer">Mastermind</a>
                <br />
                <br />
                Our task was to create an efficient codebreaker using C++, however with a twist that the number
                of colours and the length of the code would increase from 6x4 to 15x15! Multiple algorithms were allowed, so
                it came down to clever thinking paired with a lot of trial and error to find bounds to minimise 
                the number of attempts/passes taken to find the code within the time limit. As a starting point,
                we were given the hint to look into Knuth's algorithm for Mastermind, which is regarded as one of 
                the best algorithms for the 6x4 case, solving the code in around 4.34 turns to solve. Knuth's
                algorithm relies on minimax, which refers to minimising the maximum loss (i.e. worst case loss).
                <br />
                <br />
                
            </div>
        </div>
    )
}
