import React from 'react'
import ProjectHeader from '../ProjectHeader'

export default function Bookbetter() {
    return (
        <div className="page-container">
            <ProjectHeader title="BookBetter" subtitle="AS Computer Science Project - November 2016 to April 2017" />
            <div className="page-section">
                <div>
                    <img className="full-img" src="/BookBetter/login.png" alt="Login Screen" />
                </div>
                <div className="status">DETAILS COMING SOON</div>
            </div>
        </div>
    )
}
