import React from 'react'
import ProjectHeader from '../ProjectHeader'

export default function EERover() {

    const handlePause = () => {
        let vid = document.getElementById("vid");
        if (vid.paused) {
            vid.play();
        } else {
            vid.pause();
        }
    }

    return (
        <div className="page-container">
            <ProjectHeader title="EERover" subtitle="1st Year Group Project (1st Place) - November 2018 to May 2019" />
            <div className="page-section">
                <div>
                    <img className="full-img" src="/EERover/eerover_prof.jpeg" alt="EERover" />
                </div>
            </div>
            <div className="page-section">
                The EERover was the name given to the first-year group project. Our team name was Lubuya and comprised:
                me (the project manager), Catalin Craciun (the technical head), Vinay Mittal 
                (the treasurer), <a className="link" href="https://github.com/OleStrohm" target="_blank" rel="noopener noreferrer">Ole Str&#0248;hm</a> (the director of programming), 
                and Shenghua Duan (the mechanical head). No member of the group
                was forced to be confined only in their "department" and we made sure that everyone had a crack at
                everything. After a great performance at the final live demo, we were awarded the <b>Top First Year Group Project</b> prize
                for not only correctly identifying all the lizards, but also doing it in the shortest amount of time!
                The project brief was as follows: create a <b>remote-controlled rover</b> that was able to 
                navigate a forest-like terrain and <b>identify "lizards"</b> based on the <b>signals</b> they were 
                emitting. We were provided with an Adafruit Microcontroller (basically an Arduino) with a WiFi shield. 
                There were a total of 4 signals that we could use to differentiate between the 6 species, shown below:
                <div className="section-padding section-75">
                    <img className="full-img" src="/EERover/signals.png" alt="Signals" />
                </div>
                Fun fact, the names of the species were based on current and past EEE Imperial alumni. A specification was 
                also provided which added design constraints to the rover:
                <div className="section-padding section-75">
                    <img className="full-img" src="/EERover/demo_env.png" alt="Demo Environment" />
                </div> 
            </div>
            <div className="page-section">
                <div className="text-subtitle">Design Overview</div>
                Given the constraints to the design of the rover, initial designs were drawn up. The main priorities
                were to make sure a light yet sturdy material was used as well as a raised height to ensure that the
                15mm obstacles could be traversed. The end demo was to be judged on the correctness of each lizard and
                the time taken to complete. This led to a major early decision of using <b>4 motors</b> instead of 2 for the
                added speed with some sacrifice to weight, hence the light material requirement. Here are some early
                designs:
                <div style={{padding:"10px 0px"}} className="images-container-1">
                    <img className="des-img" src="/EERover/early-1.png" alt="Early Design" />
                    <img className="des-img" src="/EERover/early-3.png" alt="Early Design" />
                    <img className="des-img" src="/EERover/early-2.png" alt="Early Design" />
                </div>
                There were pros and cons to each design, the first one offers a tank-like structure, which would
                provide a strong grip to the surface of the demo environment and the ability to maneuver over obstacles
                somewhat easily. However, speed, along with low power consumption, would be sacrificed. The second
                design was produced keeping in mind the ideas discussed earlier to keep the rover light and raised
                slightly while the third was produced for the sturdy enclosed body it provides. The final rover took
                aspects from these designs; the body from the third one and the placement of the motors
                with the height adjustment from the second. Here are some <b>SolidWorks</b> model views of the final body:
                <div className="section-padding section-75">
                    <img className="full-img" src="/EERover/side_view_1.png" alt="Demo Environment" />
                </div> 
                <div className="section-75">
                    <img className="full-img" src="/EERover/side_view.png" alt="Demo Environment" />
                </div> 
            </div>
            <div className="page-section">
                <div className="text-subtitle">Sensors</div>
                There were a total of four sensors that were required to be 
                designed; <b>radio frequency (RF)</b>, <b> infrared (IR)</b>, <b>ultrasonic</b>, and <b>magnetic</b>. Below, I will briefly discuss the 
                process of producing the final circuits for each sensor. Each group was provided with a test lizard that could
                be configured to produce each signal. Note that I will go from easiest to hardest in terms of implementation
                and time spent. I will also add a circuit diagram for each sensor and pictures where possible.
                <br></br>
                <br />
                The <b>magnetic</b> sensor was a very simple circuit. Only the polarity of the magnetic field was required so
                a bipolar Hall Effect sensor was used. The hall sensor had a resting voltage at half of the rail (1.65V since
                3.3V was being used for all the sensors) and would go all the way to 3.3V when a North pole was detected and down
                to 0V if a south pole was detected. This output was sent into the analogue input of the Arduino, where the onboard
                ADC converted the signal to a digital value. In the code, a threshold was set so that anything above 2V was North and
                anything below 1V was a South. A low pass filter was also used at the input to ensure that no high-frequency noise was
                picked up. No amplification was required for this sensor.
                <div className="section-padding">
                    <img className="full-img" src="/EERover/magnetic.jpeg" alt="Magnetic Circuit"></img>
                </div>
                For the acoustic signals, we were told that the signal had a frequency of 40kHz, which sits in the
                ultrasound frequency range. So, an <b>ultrasonic</b> sensor was used to detect these signals. The signal was being
                transmitted from the "mouth" of the lizard meaning it was very directional and would not diffract as much. Initially,
                the circuit was set up as a simple potential divider but it was soon realised that the output signal needed some
                amplification. An MCP6292 op-amp was used for the amplification (the same one which will be used for all amplification)
                in a non-inverting setup, to provide a 30dB gain. A threshold in the Arduino was used again to detect the presence of
                any signal (since the sensor would only detect 40kHz).
                <div className="section-padding">
                    <img className="full-img" src="/EERover/acoustic.jpeg" alt="Acoustic Circuit"></img>
                </div>
                The <b>IR</b> sensor was a phototransistor; it detects different levels of light and uses the light to alter currents
                and create electrical signals. Initially, the sensor was set up as a potential divider too. There was an added complexity
                with this sensor as two different frequency signals were to be detected. Testing revealed that the 353Hz signal was being
                picked up just fine, but the 571Hz signal required some amplification. After adding the same non-inverting amplifier
                as the one used in the ultrasound sensor circuit, a new problem appeared. This sensor was not tuned to pick up a certain
                range of light, so it would pick up the light in the lab and a 50Hz noise component appeared, acting almost like a DC
                offset which for any sort of amplification is a real spanner in the works. The issue was easily resolved by using a passive
                low pass filter with a cut-off frequency of around 100Hz before the amplifier block. A <b>Schmitt Trigger</b> was added as a final
                block to the circuit to ensure that a stable digital output was provided, as this allowed the Arduino to easily detect and
                calculate the frequency of each signal.
                <div className="section-padding">
                    <img className="full-img" src="/EERover/ir.jpeg" alt="IR Circuit"></img>
                </div>
                <div>
                    <img className="full-img" src="/EERover/IMG_20190510_131911.jpg" alt="IR Circuit"></img>
                </div>
                Last but not least, the <b>RF</b> sensor. This was the one sensor where the group had no prior knowledge of. After a few
                helpful tips to point us in the right direction from the module leader, research began on how to go about making a radio
                frequency antenna. One day in the lab, after a long day of just research, I decided to take action and just wrap a coil of
                wire around my hand. The coil of wire was connected to 5V and ground, and a signal appeared when the test lizard was set 
                to RF mode. An improved version of the coil was made (shown below) and a capacitor was also added for <b>resonance</b>, but it was
                picked such that it provides some resonance for both frequencies. Later on, transistors were used as switches to
                change between the resonance capacitances of each frequency. The brief told us that the signal was going to be modulated, 
                and after a few Signals and Communications lectures, it was apparent that <b>Amplitude Modulation (AM)</b> was used. This signal could 
                be demodulated easily using an <b>envelope detector</b> block which consists of a diode that acts as a rectifier to turn the signal 
                into a single-ended signal i.e. no negative voltages and a low pass filter to then remove the carrier frequency. However, 
                the diode requires a signal with at least 0.7V to work, so we had two stages of amplification, one before the envelope 
                detector and another after. A final Schmitt Trigger is also added to once again provide a stable digital output. The Arduino
                now has two goals; to identify the frequency of the signal and also decode it according to the <b>UART</b> specification.
                <div className="section-padding">
                    <img className="full-img" src="/EERover/rf.jpeg" alt="RF Circuit"></img>
                </div>
                <div on className="images-container-1">
                    <img style={{width:"30%"}} src="/EERover/IMG_20190320_141756.jpg" alt="RF Circuit"></img>
                    <img style={{width:"68%"}} src="/EERover/IMG-20190220-WA0006.jpg" alt="RF Circuit"></img>
                </div>
            </div>
            <div className="page-section">
                <div className="text-subtitle">Software</div>
                <div style={{textAlign:"center"}}>
                    <video id="vid" onClick={handlePause} className="android-img android-left" loop autoPlay muted>
                        <source src="/EERover/final_5faac240e69a820094d1637e_658322.mp4" type="video/mp4" />
                        Cannot find video!
                    </video>
                </div>
                As mentioned before, an Arduino microcontroller was powering all the sensors and also performing all the logic with the
                raw data that is gathered. Using the WiFi shield, the Arduino could "pair" with our custom Android app, and send back
                all the data when it was asked for data from a certain sensor. Essentially the microcontroller would not process data from
                the sensor until it was explicitly told to do so, which prevented the Arduino from overloading. Through an <b>H-bridge</b>, the
                motors of the rover were also controlled by the microcontroller so everything needed to run smoothly to
                prevent stalling of operations. The video shows the first ride of the rover using the remote control app and a very early version 
                of the rover, based on a lab project we completed earlier in the year.
                <br />
                <br />
                The app (screenshots below) utilises <b>UDP</b> to create the initial connection between itself and the rover. Once connected, it 
                uses <b>TCP</b> for all further data transfer due to the property of TCP allowing for data to "stream" through. After a sensor is chosen, the
                Arduino uses hardware interrupts to check if any new data is present from the sensor input, as opposed to a naive solution
                of sampling, which could mean that some information is lost if the sampling time is not tuned per sensor. For the motor speed control,
                the H-bridge is provided with <b>PWM signals</b>. This has two benefits: the Arduino can easily produce PWM signals as opposed to
                continuous analogue signals and the full torque of the motor is utilised with inertia as the motor is always either fully on or off.
                <div className="section-padding section-75">
                    <img className="full-img" src="/EERover/IMG-20190514-WA0003.jpg" alt="App Screenshot 1"></img>
                </div>
                <div className="section-75">
                    <img className="full-img" src="/EERover/IMG-20190514-WA0006.jpg" alt="App Screenshot 1"></img>
                </div>
            </div>
            <div className="page-section">
                <div className="text-subtitle">Final Rover</div>
                There were a lot of changes made to the rover when getting closer to the final demo day. Firstly, four motors required their
                own separate power source so instead of piggybacking off of the Arduino power (which couldn't really provide enough current
                anyway) another 9V battery was added into the rover to power the motors. On top of that, a piece of acrylic was used to align
                the sensors so that they were facing downwards with a 45-degree angle so that the sensors were more readily pointing to the
                lizards. This unfortunately meant the rover was (just) over the
                weight limit. To shave some weight, the top and bottom panels of the rover were replaced from acrylic to a much lighter wood, with
                lots of rectangular slots cut out. A lot of duct tape for electrical insulation later, we had our final (and winning) rover:
                <div className="section-padding">
                    <img className="full-img" src="/EERover/eerover-table.jpeg" alt="Rover in lab"></img>
                </div>
            </div>
        </div>
    )
}
