import React from 'react'
import { Link } from 'react-router-dom'

export default function ProjectHeader(props) {

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="inner-page-header">
            <Link className="back-arrow" to="/projects">
                <img src="/back-arrow-3.svg" alt="back" />
            </Link>
            <div className="page-title">{props.title}</div>
            <div className="section-padding">{props.subtitle}</div>
        </div>
    )
}
