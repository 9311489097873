import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Header from './Header';
import Home from './pages/Home';
import Projects from './pages/Projects';
import TVControl from './pages/TVControl';
import NotFound from './NotFound';
import Android from './pages/Android';
import IdeasLab from './pages/IdeasLab';
import Monica from './pages/Monica';
import Mastermind from './pages/Mastermind';
import EERover from './pages/EERover';
import Bookbetter from './pages/Bookbetter';
import MorseCode from './pages/MorseCode';
// import Charco from './pages/Charco';
import './index.css'


function App() {
  return (
    <Router>
      <Header />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/projects" exact component={Projects} />
        <Route path="/projects/tv-control" component={TVControl} />
        <Route path="/projects/ideas-lab" component={IdeasLab} />
        <Route path="/projects/android" component={Android} />
        <Route path="/projects/monica" component={Monica} />
        <Route path="/projects/mastermind" component={Mastermind} />
        <Route path="/projects/eerover" component={EERover} />
        <Route path="/projects/bookbetter" component={Bookbetter} />
        <Route path="/projects/morse-code" component={MorseCode} />
        {/* <Route path="/projects/charco" component={Charco} /> */}
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
