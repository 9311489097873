import React from 'react'
import ProjectHeader from '../ProjectHeader'

export default function IdeasLab() {
    return (
        <div className="page-container">
            <ProjectHeader title="Ideas Lab System" subtitle="Placement with Dyson School of Design Engineering - July to September 2020" />
            <div className="page-section">
                <div className="video-container">
                    <iframe
                        title="youtube-video"
                        width="1225" height="689"
                        src="https://www.youtube.com/embed/dr2ubYUPZyc"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen>
                    </iframe>
                </div>
                The Ideas Lab is a student-led IoT makerspace, located in the heart of the South
                Kensington campus at Imperial College London. In this project, I worked with <a className="link" href="https://olivercolebourne.com/" target="_blank" rel="noopener noreferrer">Oliver Colebourne</a>
                , <a className="link" href="https://colinlaganier.com/" target="_blank" rel="noopener noreferrer">Colin Laganier</a> and <a className="link" href="https://www.michaelhofmann.com/" target="_blank" rel="noopener noreferrer">Michael Hofmann</a> to 
                create a complete software system for the lab. The video above outlines the main components of the software system.
            </div>
        </div>
    )
}
