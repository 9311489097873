import React from 'react'
import ProjectHeader from '../ProjectHeader'

export default function Android() {

    const handlePause = () => {
        let vid = document.getElementById("vid");
        if (vid.paused) {
            vid.play();
        } else {
            vid.pause();
        }
    }

    return (
        <div className="page-container">
            <ProjectHeader title="Custom Android App" subtitle="Personal Project - June 2020" />
            <div className="page-section">
                <video id="vid" onClick={handlePause} className="android-img android-left" loop autoPlay>
                    <source src="/Android/android-app.mp4" type="video/mp4" />
                    Cannot find video!
                </video>
                I have always wanted to make my own Android app but I just never
                had the time to. Covid-19 lockdown was the perfect time to learn
                and just make something for fun. Before I delved into <b>Android Studio</b>,
                I played around with <b>Java</b>, completing beginner courses I found online.
                The syntax was similar to that of C++ but with a heavy focus on classes.
                Once I could understand Java (somewhat) I decided to get started on
                Android Studio. Video tutorials on YouTube helped me figure out the navigation
                of the application as well as teaching me about the basic layouts that
                can be adopted for any <b>Activity</b> (essentially a page of the app).
                <br />
                <br />
                I quickly realised how important it was to label the views with a <b>unique ID</b>
                &nbsp;so that they could easily be referenced. Many conventions were 
                stated in different tutorials, but I decided to stick with CamelCase
                for my component IDs. The first functionality of my app was a basic Google
                search, giving me some insight into how a <b>GET</b> request works. Next, I wanted
                to look into how one goes about passing data from one activity to the next.
                This can be done using something known as an <b>Intent</b>. Using this, I produced
                a second activity that just displays whatever the user types into the first
                page. Simple yes, but it gave me an idea of how data transfer works.
                <br />
                <br />
                I also added authentication to the app, using <b>Google Firebase</b>. This added a login
                screen to the app and handled all the security side of things. It followed the theme of Android
                Studio of being simple to implement.
            </div>
            <div className="page-section flex">
                <div>
                <div className="text-subtitle">Calculator Mode</div>
                <img className="android-img android-right" src="/Android/android-calc.jpg" alt="android calc" />
                Next, I wanted to work on a simple beginner project - a calculator. This project
                would teach me how to utilise a grid layout and how to display "live" data onto
                the screen as it is being clicked. This is where the <b>Layout Editor</b> was really put
                to use; I was easily able to select <b>Grid Layout</b> and add the 16 blocks I needed for
                the calculator.
                <br/>
                <br/>
                A big part of Android Studio is the <b>anchoring</b> as it makes sure that
                the display still looks fine on different devices with different sizes. It was at
                this point where I started looking into how I make these designs somewhat <b>responsive</b>.
                For the case of the calculator, each button's width and height are set depending on
                the width and height of the screen itself. This ensured that the buttons still fit
                the whole screen, no matter what screen it was. The same was done with the fonts
                (the tutorial I followed pushed the idea of not using absolute lengths).
                <br/>
                <br/>
                The logic for the calculator was straightforward once I knew which button was pressed.
                Every time a button is pressed and nothing is currently being displayed, this number was
                put on the screen. When an operand button is pressed, the value on the screen (if not
                empty) is shifted up and decreased in font size. The reason for this was two-fold; to show
                the user what their current calculation is and so that I was able to access the value
                when the next operand or equals is pressed. There were a few bugs, the biggest one 
                (which crashed the app) was when a user divided anything by 0, but this was easily 
                fixed by adding a simple check. With this, the calculator was complete.
                </div>
            </div>
        </div>
    )
}
