import React from 'react'
import ProjectHeader from '../ProjectHeader'

export default function Monica() {

    const handlePause = () => {
        let vid = document.getElementById("vid");
        if (vid.paused) {
            vid.play();
        } else {
            vid.pause();
        }
    }

    return (
        <div className="page-container">
            <ProjectHeader title="Monica" subtitle="2nd Year Group Project (3rd Place) - October 2019 to March 2020" />
            <div className="page-section">
                <div>
                    <img className="full-img" src="/Monica/front_view.jpg" alt="Front view" />
                </div>
                <div className="section-padding">
                    <img className="full-img" src="/Monica/sideview.jpg" alt="Side view" />
                </div>
                Monica is the name of the solution we created for our second-year group project, for which we attained
                the <b>3rd Place prize</b>. The goal in this project was to <b>use engineering for good</b> i.e. create a solution
                for a real engineering problem that exists and hinders people. We decided to pursue the problem of people 
                with <b>mobility impairments</b> not being able to use computers <b>efficiently</b>, especially in an office 
                environment. After <b>market research</b>, we found out that there was a <b>lack of affordable solutions</b> and they 
                were not as efficient as they could be.
            </div>
            <div className="page-section">
                <div className="text-subtitle">Overview</div>
                There are two main parts of this solution; the <b>joystick with sip-and-puff</b> to emulate the mouse and 
                the <b>speech-to-text</b> for the keyboard aspect. An <b>Arduino Micro</b> was used to gather all the raw data and transfer it
                serially to the computer. <b>Python</b> scripts were used to run the software on the computer, both back-end, and
                front-end (using <b>Tkinter</b>). Each component was <b>threaded</b> so that the program could run without any interruptions from other
                components. The image below provides a high-level overview of the software:
                <div className="section-padding">
                    <img className="full-img" src="/Monica/highlevelsoft.png" alt="High Level Software Overview" />
                </div>
            </div>
            <div className="page-section">
                <div className="text-subtitle">Mouth Joystick</div>
                <div>
                    <img className="full-img" src="/Monica/closeup_joystick.jpg" alt="Close up joystick" />
                </div>
                The first component of the solution is a joystick that is operated using the user's mouth. The movement
                of the joystick itself controls the movement of the mouse. Using an Arduino Micro, every <b>300ms</b>, the joystick displacement
                is recorded, packaged into bytes, and transferred through the serial port to the computer. A python program,
                which is listening for these bytes of data, then displaces the mouse movement using a <b>custom function</b> that
                separates the movements into tiny steps per a tiny time delay, like a <b>dx/dt</b>. This ensures that the mouse runs
                smoothly and <b>does not lag</b> due to the 300ms delay.
                <div>
                    <video className="monica-portrait" id="vid" src="/Monica/mouse-working.mp4" onClick={handlePause} loop autoPlay />
                </div>
                <div style={{ paddingTop: "10px" }}>
                    On top of this, there is also the addition of a pressure sensor (which can be seen on the other side of the breadboard in
                    the video). This pressure sensor enables the user to perform actions, such as left-clicking, by <b>sipping or puffing</b> into
                    a mouthpiece connected on top of the joystick. This mouthpiece feeds directly into the pressure sensor via a tube to ensure 
                    strong pressure detection. Similar to the joystick, the pressure sensor data is read and transferred every 300ms. Threshold 
                    values are compared to see whether a sip or puff was detected, with the corresponding action performed.
                    <br />
                    <br />
                    To add more action mappings, the user can perform a <b>long</b> sip/puff as well as a <b>double</b> sip/puff,
                    allowing for a total of six quick actions. These actions can also be mapped to keyboard shortcuts (like Ctrl/Cmd + S) and will change depending
                    on the mode the user is in.
                    <br />
                    <br />
                    The Arduino Micro and pressure sensor are stored inside of a <b>3D printed box</b>, with the joystick protruding out.
                    This box is attached to a simple monitor arm, which allows for easy adjustment for the device so that it can
                    be set up to be in the most comfortable position. The device is positioned at a 45-degree angle such that it does
                    not hinder the line of sight to the computer.
                </div>
            </div>
            <div className="page-section">
                <div className="text-subtitle">Speech-to-Text + GUI</div>
                For the keyboard side of things, we utilised speech-to-text (STT) using the <b>Google Cloud Engine</b>. STT for typing
                out words is very good already, so there was no need to improve that. However, we wanted to 
                add <b>mode-based functionality</b>. Essentially, the user is able to change the mode they are in currently, 
                and depending on this mode the STT will listen for certain keywords, and rather than type, an action
                linked to the keyword will be performed. For example, the user can go into a mode called Format Mode. 
                In this mode, the word "copy" corresponds to the keyboard shortcut to copy, Ctrl/Cmd + C.
                <br/>
                <br/>
                There are four preset modes available, with the option to add as many more as the user wants:
                <ul>
                    <li>Type - normal typing of words, no keywords</li>
                    <li>Format - common formatting shortcuts such as copy, paste, bold, etc.</li>
                    <li>Code - preset for C++ as of now and can add simple sections of code such as <code>std::cout</code> or <code>std::endl</code></li>
                    <li>Chrome -  allows quick navigation through phrases such as new tab, left/right for switching between tabs, etc.</li>
                </ul>
                <div>
                    <img className="full-img" src="/Monica/guibar.png" alt="GUI" />
                </div>
                To aid the user with the device, a <b>GUI</b> that sits on top of all the applications was produced using Tkinter. 
                This GUI can also become collapsable on the left-hand side of the screen or completely minimised.
                It allows the user to view or change the current mode, view the transcription of what they just said, view what sip/puff
                they recently activated, or adjust the settings. When it comes to settings, we wanted to make sure that the user is able to
                adjust almost whatever setting they wish to, as the usage of the device differs per user.
                <div className="sbs-img-container section-padding">
                    <img className="sbs-img" src="/Monica/sipandpuffsets.png" alt="GUI Settings 1" />
                    <img className="sbs-img" src="/Monica/transcriptionsets.png" alt="GUI Settings 2" />
                </div>
            </div>
        </div>
    )
}
